* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "PT Sans", sans-serif;
}

.home,
.services,
.products,
.sign-up {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.services {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.menu {
  background-position: center;
  background-size: fill;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.sign-up {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.foodContainer {
  padding: 2%;
  text-align: center;
  display: grid; 
  grid-auto-columns: 1fr; 
  grid-template-rows: 0.3fr 2.3fr 0.4fr ; 
  gap: 0px 0px; 
}

.smallImg{
  max-width: 163px;
  padding-top: 20px;
}

.smallListText{
  text-align: center;
  margin: auto;
}

.addBtn{
  position: absolute;
  bottom: 10%;
  right: 1%;
}

.addContainer {
  padding: 2%;
  text-align: center;
  display: grid; 
  grid-auto-columns: 1fr; 
  grid-template-rows: 1fr 1fr ; 
  gap: 0px 0px; 
}

h2{
  color: black;
}

.notVisible{
  display: inline-block;
  text-align: center;
  z-index: 2;
  width: 20%;
  height: 70%;
  position: fixed;
  padding: 2% 5% 0% 5%;
  left: 40%;
  bottom: -70%;
  color: #fff;
  background-color: rgb(86, 86, 86);
  transition: all 0.25s ease-in;
}

.editWindow{
  text-align: center;
  z-index: 2;
  width: 60%;
  height: 70%;
  position: fixed;
  padding: 2% 1% 1% 1%;
  left: 20%;
  bottom: 0;
  color: #fff;
  background-color: rgb(230 230 230);
  transition: all 0.25s ease-in;
  overflow: auto;
}

.recipeImg{
  position: absolute;
  width: 20%;
  height: 20%;
  right: 40%;
  top: 35%;
}

.invisible{
  display: none;
}

.showAll{
  position: absolute;
  bottom: 10%;
  right: 1%;
}

.addLinkButton{
  position: absolute;
  bottom: 10%;
  left: 1%;
}

.homeContainer{

     text-align-last: center;
     width: 80%;
    margin: auto;
    padding: 40px;

}
.aboutContainer{

     text-align-last: center;
     position: absolute;
     top: 15%;
     left: 40%;
}

.tombolaContainer{

  text-align-last: center;
  position: absolute;
  top: 15%;
  left: 43%;
}

.listOfRecipes{
  text-align:  center;
}

.imgDiv{
  position: absolute;
  left: 27%;
  margin: auto;
  width: 46%;
  height: 73%;
  
  max-height: 10vh;
}

.imgDiv img{
  width: 100%;
  object-fit: contain;
  max-height: 30vw;
}